import React from "react"
import { graphql } from "gatsby"
import Layout from "~/components/Layout"
import Spacer from "../components/reusable/svgs/Spacer"
import { buildBlocks } from "../services/builder"

export default function Page({ data, pageContext }) {
  const { wpPage } = data
  const blocks = buildBlocks(wpPage.blocks, {}, {}, wpPage.id)

  return (
    <Layout
      meta={wpPage.Meta}
      path={pageContext.pagePath}
      cta={wpPage.PageCTA}
      title={wpPage.title}
    >
      {blocks}
      <Spacer top={`yellow`} bottom={`black`} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`
